.admission{
    width: 100%;
    padding:25px;
    min-height: 70vh;
}
.titleContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}