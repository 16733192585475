.about{
    min-height: 70vh;
    width: 100%;
    padding:25px;

}
.titleContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title{
    /* align-self: center; */
    color: var(--primary);
    /* position: absolute;
    top:50%;
    left: 50%; */
}

.imageCH{
    height: 250px;
    margin-bottom: 20px;
}
.img{
    height: 250px;
    width: 100%;
    object-fit: fill;
    background-size: cover;
}