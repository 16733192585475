.container {
  width: 100%;
  height: 50vh;
  padding: 0%;
  margin: 0%;
  justify-content: center;
  align-items: center;
}
.cImg {
  width: 100%;
  object-fit: fill;
  height: 75vh;
}
.captionCont {
  justify-content: center;
  align-items: center;
  display: flex;
}
.carasoulTitle {
  color: var(--primaryFont);
  font-weight: 600;
  background-color: black;
  opacity: 0.6;
  padding-left: 15px;
  padding-right: 15px;
}
