.header {
  background-color: var(--primary);
  padding-bottom: 0%;
  padding-top: 0%;
  width: 100%;
  flex-direction: column;
}

.fontColor {
  color: var(--fontColor);
}
.fontColor:hover {
  font-weight: 600;
  /* box-shadow: inset 120px 0 0 0 var(--menuHoverColor); */
  background-color: var(--menuHoverColor);
  color: var(--hover);
}
.schoolName {
  margin-bottom: 0;
  font-size: small;
}
.affilation {
  margin-top: 0;
  margin-bottom: 0;
  font-size: small;
  width: "100%";
  color: var(--primaryFont);
}

.offCanvas {
  background-color: var(--primary);
}

.logoBrandContainer {
  margin-left: 5%;
  margin-right: 0%;
  align-items: center;
  justify-content: center;
}

.logoBrandName,
logoBrandNameD {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .header {
    padding: 0%;
  }
  .logo {
    height: 50px;
    width: 50px;
  }
  .logoBrandContainer {
    margin-left: 2%;
  }
  .schoolName {
    font-size: x-small;
  }
  .affilation {
    font-size: xx-small;
  }
  .navbarIcon {
    width: 40px !important;
    height: 40px !important;
    padding: 0px !important;
  }
  .logoBrandNameD {
    display: none;
  }
}
