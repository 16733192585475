.home{
    min-height: 75vh;
    width: 100%;
}
.cardContainer{
    padding: 5%;
    background-color: var(--backgroundColor)
    
}
.imgColumn{
   padding-top:30px ;
   background-color: var(--backgroundColor);
}
.imageCon{
    height: 200px;
    width: 200px;
}
.imgC{
    height: 200px;
    width: 100%;
}
.imgC :hover{
    transform: scale(1.5) !important;
}