.cardHeader {
  color: var(---primary);
}
.cardImage {
  height: 100px;
  width: 100px;
  border-radius: 50px;
}
.cardBody {
}

.documentCarddd {
  display: flex;
  justify-content: "space-between";
  align-items: "center";
  background-color: black;
}
