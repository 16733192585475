/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital@0;1&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital@0;1&family=Roboto&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
/* Global colors */

:root {
  --primary: #e74215;
  --primaryFont: #ffffff;
  --backgroundColor: #f7f7f7;
  --hover: blue;
  --menuHoverColor: #54b3d6;
  --white: #ffffff;
  --grey: #808080;

  margin: 0;
  overflow-x: hidden;
}
body {
  /* font-family: "Open Sans", sans-serif; */
  /* font-family: "Roboto", sans-serif;
   */
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
  letter-spacing: normal;
}
.primaryTextColor {
  color: var(--primary);
}
.paragraphDetail {
  text-align: justify;
}
