.facilityCard {
  margin-bottom: 30px;
}
.cardTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.imgCard {
  height: 200px;
  width: 100%;
  object-fit: fill;
  background-size: cover;
}
.desc {
 text-align: justify;
 margin-top: 10%;
 margin-bottom: 10px;
}
