.card {
  border: #f8f8f8;
  -webkit-box-shadow: -1px -1px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px -1px 2px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -1px -1px 2px 0px rgba(0, 0, 0, 0.75);
}

.cardHeader {
  background-color: #f8f8f8;
  color: var(--primary);
  font-weight: bold;
}

.cardImage {
  align-self: center;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-top: 20px;
}
