.footer {
  background-color: var(--primary);
  min-height: 15vh;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 20px;
}
.footerNav {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.contactImg {
  width: 150px;
  height: 120px;
}

.contactHeader {
  color: var(--white);
  margin-bottom: 0;
}

.schoolName {
  font-size: small;
  color: var(--white);
  margin-bottom: 3px;
}
.otherCol {
  height: 280px;
}

.footerCard {
  margin-top: 25px;
  padding: 0.2px;
  color: var(--white);
  text-decoration: none;
  width: "60%";
}

.footerCard:hover {
  color: skyblue;
}

.socialLinks {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}
.socialIcons:hover {
  color: skyblue;
}
.scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .footer {
    display: flex;
    flex-direction: column !important;
    padding: 10px;
    align-items: center;
    justify-content: center;
  }
  .firstCol {
    width: 100%;
  }
  .otherCol {
    width: 100%;
  }
  .footerNav {
    margin-bottom: 10px;
    width: 100%;
  }
  .schoolName {
    font-size: medium;
  }

  .contactHeader {
    font-size: x-small;
    text-align: left;
  }
  .footerCard {
    font-size: small;
    margin-bottom: 4px;
    margin-top: 4px;
  }
  .mapouter {
    position: relative;
    text-align: right;
    width: 80%;
    height: 400px;
  }
  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 300px;
  }
  .gmap_iframe {
    height: 400px !important;
  }
}
@media screen and (min-width: 480px) {
  .schoolName {
    font-size: x-large;
  }
}
