.facility{
    min-height: 75vh;
    padding:25px;
    background-color: var(--white);
}
.titleContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageCH{
    height: 250px;
    margin-bottom: 20px;
}
.img{
    height: 250px;
    width: 100%;
    object-fit: contain;
    background-size: cover;
}